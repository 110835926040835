import React from 'react'
import { IconPaths } from './IconPaths'

export type IconNameType = keyof typeof IconPaths

export type IconColorType =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'white'
  | 'info'
  | 'inherit'
  | 'success'
  | 'disabled'
  | 'warning'

export interface IconProps {
  /**
   * Nombre del icono a utilizar
   */
  name: IconNameType
  /**
   * Nombre de clase utilizada (o muchas clases separadas por un espacio) para ser aplicada en el svg
   */
  className?: string
  /**
   * Colores disponibles para aplicar a los iconos
   */
  color?: IconColorType
  /**
   * Tamaño del icono (se utiliza el mismo tamaño para width y height)
   */
  size?: string | number
}

/**
 * Componente
 */
export const Icon = ({
  className = '',
  name,
  color = 'primary',
  size = '16px'
}: IconProps) => {
  return (
    <svg
      className={`g-icon icon-${color} ${className}`}
      viewBox='0 0 30 30'
      width={size}
      height={size}
    >
      {IconPaths[name].map((path, i) => (
        <path key={`icon_${i}`} d={path} />
      ))}
    </svg>
  )
}
