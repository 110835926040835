import React from 'react'
import { Alert as BaseAlert } from 'reactstrap'
import { Icon } from '../Icon'
import { IconName } from '../Icon/IconPaths'

export type AlertProps = {
  /**
   * Texto que tendrá la alerta
   */
  text: string
  /**
   * Colores obtenidos desde las variables (primary and secondary) del tema
   */
  color?: 'primary' | 'secondary' | 'danger' | 'info'
  size?: 'sm' | 'md' | 'lg'
  /**
   * Nombre de uno de los iconos disponibles o un componente react
   * que se agregará al lado izquierdo del texto de la alerta
   */
  icon?: keyof typeof IconName | React.ReactElement
  className?: string
  noFill?: boolean
  style?: React.CSSProperties
  /**
   * La alerta es visible
   */
  isOpen?: boolean
  /**
   * Función para cerrar el dialogo con el botón
   */
  toggle?: React.MouseEventHandler<any>
  fade?: boolean

  outline?: boolean
}

/**
 * Componente utilizado para renderizar distintas variantes de un botón.
 */
export const Alert = ({
  text,
  color = 'primary',
  size = 'lg',
  icon,
  className = '',
  style,
  noFill = false,
  isOpen,
  toggle,
  fade,
  outline = false
}: AlertProps) => {
  const iconSize = size === 'lg' ? '36px' : '19px'
  return (
    <BaseAlert
      className={`g-alert ${noFill ? 'g-alert-no-fill' : ''} ${
        outline ? 'bg-white' : ''
      } ${size ? `g-alert-${size}` : ''} ${className}`}
      color={color}
      size={size}
      style={style}
      isOpen={isOpen}
      toggle={toggle}
      fade={fade}
    >
      <div className='d-flex align-items-center'>
        {icon && (
          <div className='icon-btn'>
            {typeof icon === 'string' ? (
              <Icon name={icon} color={color} size={iconSize} />
            ) : (
              icon
            )}
          </div>
        )}
        <span>{text}</span>
      </div>
    </BaseAlert>
  )
}
