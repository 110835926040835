import React from 'react'

export type FlagProps = {
  code: string
  size?: number
}

export const Flag = ({ code, size = 25 }: FlagProps) => {
  return (
    <span
      style={{
        fontSize: `${size}px`,
        fontFamily: 'BabelStoneFlags'
      }}
    >
      {typeof String.fromCodePoint !== 'undefined'
        ? code
            .toUpperCase()
            .replace(/./g, (char) =>
              String.fromCodePoint(char.charCodeAt(0) + 127397)
            )
        : code}
    </span>
  )
}
