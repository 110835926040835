import React from 'react'
import clsx from 'clsx'

export type BoxProps = {
  /**
   * Título que se mostrará en la caja
   */
  title?: string
  /**
   * Cuerpo que se mostrará bajo el título (en caso de existir)
   */
  body: string | React.ReactElement
  /**
   * Colores obtenidos desde las variables (primary) del tema
   */
  color?: 'primary' | 'secondary' | 'default'
  /**
   * Variantes del Box, standard o outlined
   */
  variant?: 'standard' | 'outlined'
  /**
   * Indica si el componente ocupará todo el ancho del componente padre
   */
  fullwidth?: boolean
  className?: string
  style?: React.CSSProperties
}

/**
 * Componente utilizado para renderizar distintas variantes de un botón.
 */
export const Box = ({
  title = '',
  body,
  color = 'default',
  variant = 'outlined',
  fullwidth = false,
  className,
  style
}: BoxProps) => {
  return (
    <div
      className={clsx(
        'g-box',
        {
          'w-100': fullwidth,
          'box-standard': variant === 'standard',
          'box-outlined': variant === 'outlined',
          'box-primary': color === 'primary',
          'box-secondary': color === 'secondary'
        },
        className
      )}
      style={style}
    >
      {Boolean(title) && <span className='fw-700 text-uppercase'>{title}</span>}
      {typeof body === 'string' ? <span className='fs-16'>{body}</span> : body}
    </div>
  )
}
