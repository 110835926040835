import React, { useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Icon } from '../Icon'
import { PaginationType } from './types/TableTypes'

export type TablePaginationProps = {
  pagination: PaginationType
}

const pagesToShow = 5

/**
 * Componente de paginación, muestra por defecto un máximo 5 de páginas,
 * Se puede utilizar por separado del componente tabla
 */
export const TablePagination = ({
  pagination: { totalPages, currentPage, onChangePage }
}: TablePaginationProps) => {
  const [pages, setPages] = useState<number[]>([])

  useEffect(() => {
    let initialPage = 0
    if (totalPages > pagesToShow) {
      if (currentPage <= 2) {
        initialPage = 1
      } else if (totalPages - currentPage >= pagesToShow - 1) {
        initialPage = currentPage - 1
      } else {
        initialPage = totalPages - pagesToShow + 1
      }
    }

    const newPages = Array.from(
      { length: totalPages > pagesToShow ? pagesToShow : totalPages },
      (_, i) => (initialPage > 0 ? i + initialPage : i + 1)
    )

    setPages(newPages)
  }, [currentPage])

  const isPrevDisabled = currentPage === 1
  const isNextDisabled = currentPage === totalPages

  return (
    <Row className='g-pagination'>
      <Col xs={12} className='pages-container'>
        <div
          className={`pageBtn ${isPrevDisabled && 'disabled'}`}
          onClick={() => {
            if (!isPrevDisabled) {
              onChangePage(currentPage - 1)
            }
          }}
        >
          <Icon name='chevron_double_left' size='10px' />
        </div>

        {pages.map((page: number, i: number) => {
          if (
            (i === 0 && currentPage > 1 && totalPages > pagesToShow) ||
            (i === pagesToShow - 1 && page < totalPages)
          ) {
            return (
              <div key={`suspensive_${page}_${i}`} className='suspensiveBox'>
                ...
              </div>
            )
          } else {
            return (
              <div
                key={`page_${page}_${i}`}
                className={`pageBtn ${currentPage === page && 'current'}`}
                onClick={() => onChangePage(page)}
              >
                {page}
              </div>
            )
          }
        })}

        <div
          className={`pageBtn ${isNextDisabled && 'disabled'}`}
          onClick={() => {
            if (!isNextDisabled) {
              onChangePage(currentPage + 1)
            }
          }}
        >
          <Icon name='chevron_double_right' size='10px' />
        </div>
      </Col>
    </Row>
  )
}
