import React from 'react'

type TableCellProps = {
  width?: string
  bold?: boolean
} & JSX.IntrinsicElements['td']

export const TableCell: React.FC<TableCellProps> = ({
  width,
  bold = false,
  children,
  style,
  ...props
}) => {
  return (
    <td
      {...props}
      style={{
        minWidth: width ?? '',
        width: width ?? '',
        ...style
      }}
    >
      {bold ? <b> {children}</b> : children}
    </td>
  )
}

TableCell.defaultProps = {
  bold: false
}
