import React, { ReactElement } from 'react'
import { Card, Col, Row } from 'reactstrap'
import { Icon, IconPaths } from '../../Icon'

export type DisplayInfoProps = {
  textBody: string | ReactElement
  iconName?: keyof typeof IconPaths
  title?: string
  insideCard?: boolean
  maxWidth?: string
}

/**
 * Componente utilizada para mostrar mensaje de información.
 */
export const DisplayInfo = ({
  iconName = 'information',
  title,
  textBody,
  insideCard = false,
  maxWidth = '400px'
}: DisplayInfoProps) => {
  const Content = () => (
    <div style={{ maxWidth, alignSelf: 'center' }}>
      <Row className='text-center py-4 px-3'>
        <Col xs={12}>
          <Icon name={iconName} color='secondary' size='65px' />
        </Col>
        {title && (
          <Col xs={12} className='py-4'>
            <span className='fs-20 text-dark'>{title}</span>
          </Col>
        )}
        <Col xs={12}>
          {typeof textBody === 'string' ? (
            <p className='fs-18 fw-300'>{textBody}</p>
          ) : (
            textBody
          )}
        </Col>
      </Row>
    </div>
  )

  if (insideCard) {
    return (
      <Card className='py-4'>
        <Content />
      </Card>
    )
  } else {
    return <Content />
  }
}
