import React from 'react'
import { Icon } from '../../Icon'

const ClearIndicator = ({ innerProps }: any) => {
  return (
    <div
      className='g-select__indicator g-select__clear-indicator'
      onMouseDown={innerProps.onMouseDown}
    >
      <Icon name='close' size='12px' />
    </div>
  )
}

export default ClearIndicator
