import React from 'react'
import { Icon } from '../../Icon'

const DropdownIndicator = () => {
  return (
    <div className='g-select__indicator g-select__arrow-indicator'>
      <Icon name='chevron_down' size='14px' />
    </div>
  )
}

export default DropdownIndicator
