import React, { useEffect, useMemo, useState } from 'react'
import { FormGroup, FormText, Label } from 'reactstrap'
import DatePicker from 'react-date-picker'
import { Icon } from '../../Icon'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import dayjs from 'dayjs'

export interface DateInputProps<T> extends UseControllerProps<T> {
  label: string
  errorText?: string
  disabled?: boolean
  value?: Date | string | null
  onChange?: (date: Date | string | null) => void
  minDate?: Date
  maxDate?: Date
  dayPlaceholder?: string
  monthPlaceholder?: string
  yearPlaceholder?: string
}

export const DateInput = <T extends FieldValues>(props: DateInputProps<T>) => {
  useEffect(() => {
    // Se le agrega atributo id al primer input luego del input hidden con la fecha completa
    // para poder poner el foco en el input presionando sobre el label
    const input = document.querySelector(
      `input[name=${props.name}] ~.react-date-picker__inputGroup__input`
    )
    input && input.setAttribute('id', name)
  }, [])

  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }

  const {
    label,
    errorText,
    disabled,
    minDate,
    maxDate = new Date('12/31/9999'),
    dayPlaceholder,
    monthPlaceholder,
    yearPlaceholder
  } = props

  const { name, value, onChange } = controlledProps || props

  const [expect] = useState<'string' | 'date' | undefined>(() => {
    if (typeof value === 'string') {
      return 'string'
    } else if (value instanceof Date) {
      return 'date'
    }
    return 'date'
  })

  const dateValue = useMemo(() => {
    if (value && typeof value === 'string') {
      const [year, month = 1, day = 1] = `${value}`
        .split('-')
        .map((v) => parseInt(v))
      const proposedValue = new Date()
      proposedValue.setFullYear(year, month - 1, day)
      proposedValue.setHours(0, 0, 0, 0)
      return proposedValue
    } else if (value instanceof Date) {
      return value
    }
    return null
  }, [value])

  const [hasDate, setHasDate] = useState<null | Date | Date[]>(dateValue)

  return (
    <FormGroup>
      <div
        className={`g-date-picker-container ${
          hasDate ? 'with-value' : 'without-value'
        }`}
      >
        <DatePicker
          className='g-date-input'
          clearIcon={<Icon name='close' size='12px' />}
          calendarIcon={<Icon name='calendar' size='14px' />}
          showLeadingZeros={false}
          format='dd/MM/yyyy'
          dayPlaceholder={dayPlaceholder}
          monthPlaceholder={monthPlaceholder}
          yearPlaceholder={yearPlaceholder}
          name={name}
          value={dateValue}
          onChange={(date: Date) => {
            setHasDate(date)
            if (onChange) {
              if (expect === 'string') {
                onChange(date ? dayjs(date).format('YYYY-MM-DD') : '')
              } else {
                onChange(date)
              }
            }
          }}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />

        <Label for={name} className='g-input-label'>
          {label.toUpperCase()}
        </Label>

        <div className='g-input-border' />
      </div>
      {(errorText || controlledFieldState?.error?.message) && (
        <FormText className='g-input-error' color='danger'>
          {errorText || controlledFieldState?.error?.message}
        </FormText>
      )}
    </FormGroup>
  )
}

DateInput.defaultProps = {
  disabled: false,
  dayPlaceholder: 'dd',
  monthPlaceholder: 'mm',
  yearPlaceholder: 'yyyy'
}
