import React from 'react'
import clsx from 'clsx'
import { Group, useCollapseGroup } from './Group'
import { ToggleButton } from './ToggleButton'

type RenderElementParams = {
  isOpened: boolean
  toggle: () => void
}

export interface CollapseProps {
  title?: string
  renderTitle?: (params: RenderElementParams) => JSX.Element
  renderBtn?: (params: RenderElementParams) => JSX.Element
  children?: React.ReactNode
  id?: string | number
  headerClassName?: string
  contentClassName?: string
  headerStyles?: React.CSSProperties
  contentStyles?: React.CSSProperties
}

export function Collapse({
  title = '',
  renderTitle,
  renderBtn,
  children,
  id,
  headerClassName,
  contentClassName,
  headerStyles = {},
  contentStyles = {}
}: CollapseProps) {
  const { grouped, selectCollapse, isSelected } = useCollapseGroup()
  const [openedInternally, setOpenedInternally] = React.useState(() => {
    return grouped ? isSelected(id!) : false
  })

  const toggle = React.useCallback(() => {
    if (grouped) selectCollapse(id!)
    else setOpenedInternally((prev) => !prev)
  }, [grouped])

  const isOpened = React.useMemo(() => {
    if (grouped) return isSelected(id!)
    else return openedInternally
  }, [grouped, openedInternally, isSelected, id])

  return (
    <div className='g-collapse'>
      <div
        className={clsx(
          'd-flex justify-content-between align-items-center px-3 py-3',
          headerClassName
        )}
        style={{ ...headerStyles }}
      >
        {renderTitle ? (
          renderTitle({ isOpened: isOpened, toggle })
        ) : (
          <span className='d-block fs-14 text-dark'>{title}</span>
        )}
        {renderBtn ? (
          renderBtn({ isOpened: isOpened, toggle })
        ) : (
          <ToggleButton
            iconName={isOpened ? 'minus' : 'plus'}
            toggle={toggle}
          />
        )}
      </div>
      <div
        className={clsx('content px-3', contentClassName)}
        style={{
          ...contentStyles,
          display: isOpened ? 'block' : 'none'
        }}
      >
        {isOpened && children}
      </div>
    </div>
  )
}

Collapse.Group = Group
Collapse.ToggleButton = ToggleButton
