import React, { FunctionComponent } from 'react'
import { Card, Container } from 'reactstrap'

export type SingleCardContainerProps = {
  /**
   * Componente (imagen o svg) del logo del producto Global en color
   * (Se muestra en la izquina inferior derecha o en el centro en versión móvil)
   */
  brandLogoColor?: React.ReactElement
  /**
   * Puedes agregar un componente de link para redirigir a otra página o abrir algún modal con información
   */
  linkUndercard?: React.ReactElement

  /**
   * Contenido que se mostrara en la card blanca
   */
  children?: React.ReactNode
}

export const SingleCardContainer: FunctionComponent<SingleCardContainerProps> = ({
  brandLogoColor,
  linkUndercard,
  children
}) => {
  return (
    <div className='g-single-card-container d-flex flex-column flex-grow-1 min-vh-100'>
      <Container className='d-flex flex-grow-1 justify-content-center align-items-center py-3 px-3'>
        <div>
          <Card
            body
            className='single-card rounded-lg py-5 px-3 px-sm-5 px-lg-5'
          >
            {children}
          </Card>
          {linkUndercard && (
            <div className='text-center py-3'>{linkUndercard}</div>
          )}
        </div>
      </Container>
      {brandLogoColor && (
        <div className='text-center text-sm-right'>
          <div className='px-4 pb-4 logo-color'>{brandLogoColor}</div>
        </div>
      )}
    </div>
  )
}
