import React, { Fragment, HTMLProps, useCallback } from 'react'
import Dropzone, { DropzoneOptions } from 'react-dropzone'
import { Col, FormText, Row } from 'reactstrap'
import { Button } from '../../Button'
import { Icon } from '../../Icon'

const classes = {
  label: 'g-file-input-label',
  container: 'g-file-input-container',
  name: 'g-file-input-name'
}

export type DropZoneProps = {
  onDragEnter?: HTMLProps<HTMLElement>['onDragEnter']
  onDragOver?: HTMLProps<HTMLElement>['onDragOver']
  onDragLeave?: HTMLProps<HTMLElement>['onDragLeave']
} & Omit<DropzoneOptions, 'multiple'>

export interface FileInputProps<T = any>
  extends Omit<DropZoneProps, 'multiple'> {
  name: string
  label?: string
  value?: T
  onChange?: (e: T | null) => void
  disabled?: boolean
  error?: string
  /**
   * Texto que tendrá el botón para cargar la imagen
   */
  btnText?: string
  /**
   * Agrega un icono al costado derecho del basurero
   * para poder agregar alguna función para ver/descargar el archivo
   * seleccionado
   */
  onDownloadFile?: () => void
}

/**
 * Input para seleccionar un archivo
 */
export const FileInput = (props: FileInputProps) => {
  const {
    btnText = 'Cargar imagen',
    disabled = false,
    name,
    label,
    value,
    onChange,
    error,
    onDrop: onDropProp,
    onDownloadFile,
    ...dropZoneOptions
  } = props
  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>(
    (files, rejected, event) => {
      onDropProp && onDropProp(files, rejected, event)
      onChange && onChange(files.length ? files[0] : null)
    },
    [onDropProp, onChange]
  )
  const onTrash = useCallback(() => {
    onChange && onChange(null)
  }, [onChange])
  return (
    <div>
      <label className={classes.label} htmlFor={name}>
        {label}
      </label>
      <Dropzone {...dropZoneOptions} multiple={false} onDrop={onDrop}>
        {({ getInputProps, open }) => (
          <Fragment>
            <Row>
              <Col xs={12} sm={6}>
                <div className={classes.container}>
                  {Boolean(value) && (
                    <Fragment>
                      <span>
                        <Icon
                          name='file_img'
                          size='25px'
                          color={disabled ? 'inherit' : 'primary'}
                        />
                      </span>
                      <span className={classes.name}>{value.name}</span>
                      {Boolean(onTrash) && !disabled && (
                        <span role='button' onClick={onTrash}>
                          <Icon name='trash' size='16px' />
                        </span>
                      )}
                      {Boolean(onDownloadFile) && (
                        <span
                          className='pl-2'
                          role='button'
                          onClick={onDownloadFile}
                        >
                          <Icon name='download' size='16px' />
                        </span>
                      )}
                    </Fragment>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={6}>
                <Button
                  text={btnText}
                  color='primary'
                  outlined
                  size='md'
                  onClick={open}
                  icon='export'
                  fullwidth
                  disabled={disabled}
                />
              </Col>
            </Row>
            <input name={name} {...getInputProps()} disabled={disabled} />
            {Boolean(error) && (
              <FormText className='g-input-error' color='danger'>
                {error}
              </FormText>
            )}
          </Fragment>
        )}
      </Dropzone>
    </div>
  )
}
