import React from 'react'
import clsx from 'clsx'
import { Icon, IconProps } from '../Icon'

export interface CollapseToggleButtonProps {
  iconName: IconProps['name']
  toggle: () => void
  btnClassName?: string
  btnStyles?: React.CSSProperties
  iconColor?: IconProps['color']
}

export function ToggleButton({
  iconName,
  iconColor = 'white',
  toggle,
  btnClassName,
  btnStyles = {}
}: CollapseToggleButtonProps) {
  return (
    <button
      className={clsx('default-button', btnClassName)}
      onClick={toggle}
      style={btnStyles}
      type='button'
    >
      <Icon name={iconName} size={16} color={iconColor} />
    </button>
  )
}
