import { rutFormatter } from './rut'
import { phoneFormatter } from './phone'

export enum Formatters {
  rut = 'rut',
  phone = 'phone'
}

export const formatters = {
  rut: rutFormatter,
  phone: phoneFormatter
}
