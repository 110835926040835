import React, { useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'reactstrap'
import { Button, ButtonProps } from '../Button'
import { Icon, IconColorType, IconNameType } from '../Icon'
import { Modal } from '../Modal'

export interface DialogProps {
  type?: 'confirm' | 'info'
  icon?: {
    name: IconNameType
    color: IconColorType
  }
  title?: string
  subtitle?: string
  children?: JSX.Element[] | JSX.Element
  btnClose: {
    text: string
    onClose?: VoidFunction
    color?: ButtonProps['color']
  }
  btnConfirm: {
    text: string
    onConfirm: VoidFunction
    color?: ButtonProps['color']
  }
}

export interface DialogInfoProps
  extends Omit<DialogProps, 'type' | 'btnClose'> {}

export interface DialogConfirmProps extends Omit<DialogProps, 'type'> {}

export interface DialogArgs
  extends Omit<DialogProps, 'btnConfirm' | 'btnClose'> {
  btnConfirm?: DialogProps['btnConfirm']
  btnClose?: DialogProps['btnClose']
}

export function showDialogInfo(params: DialogInfoProps) {
  const dialogEvent = new CustomEvent('dialog', {
    detail: { type: 'info', ...params }
  })
  document.dispatchEvent(dialogEvent)
}

export function showDialogConfirm(params: DialogConfirmProps) {
  const dialogEvent = new CustomEvent('dialog', {
    detail: { type: 'confirm', ...params }
  })
  document.dispatchEvent(dialogEvent)
}

export function Dialog() {
  const [open, setOpen] = useState<boolean>(false)
  const [args, setArgs] = useState<DialogArgs>({})

  const { type, icon, title, subtitle, btnConfirm, btnClose, children } = args

  const close = useCallback(() => {
    if (btnClose && btnClose.onClose) {
      btnClose.onClose()
    }
    setOpen(false)
  }, [setOpen, btnClose])

  const showDialogConfirm = useCallback(
    (e: any) => {
      setOpen(true)
      setArgs({ ...e.detail })
    },
    [setOpen, setArgs]
  )

  const onConfirm = useCallback(() => {
    setOpen(false)
    if (btnConfirm) btnConfirm.onConfirm()
  }, [btnConfirm, close])

  useEffect(() => {
    document.addEventListener('dialog', showDialogConfirm)
    return () => {
      document.removeEventListener('dialog', showDialogConfirm)
    }
  }, [showDialogConfirm])

  return (
    <Modal isOpen={open} size='md' toggle={close}>
      <header className='text-center mb-4'>
        {icon && <Icon name={icon.name} size='52px' color={icon.color} />}
        {title && (
          <h6
            className='mt-4 fs-22'
            style={{
              color: '#000',
              fontSize: '22px'
            }}
          >
            {title}
          </h6>
        )}
      </header>
      {subtitle && <p className='fs-18 mb-5 text-center'>{subtitle}</p>}
      {children}
      <Row>
        {type === 'confirm' && btnClose && (
          <Col>
            <Button
              text={btnClose.text}
              color={btnClose.color}
              outlined
              fullwidth
              onClick={close}
            />
          </Col>
        )}
        {btnConfirm && (
          <Col>
            <Button
              text={btnConfirm.text}
              color={btnConfirm.color}
              fullwidth
              onClick={onConfirm}
            />
          </Col>
        )}
      </Row>
    </Modal>
  )
}
