import { isValidPhone } from '../validators'

/**
 * Se formatea el teléfono ingresado
 * cuando contiene un formato de celular chileno,
 * telefono fijo de la región metropolitana o de otras regiones
 * @param value
 * @returns
 */
export const phoneFormatter = (value: string): string => {
  let cleanValue = value.replaceAll(' ', '')
  // Celular chileno ó Telefono fijo región metropolitana
  if (
    isValidPhone(cleanValue, 'cl-mobile') ||
    isValidPhone(cleanValue, 'cl-phone-met')
  ) {
    return `${cleanValue.substr(0, 3)} ${cleanValue.substr(
      3,
      1
    )} ${cleanValue.substr(4, 4)} ${cleanValue.substr(8)}`
  }

  // Telefono fijo regiones
  if (isValidPhone(cleanValue, 'cl-phone-regions')) {
    return `${cleanValue.substr(0, 3)} ${cleanValue.substr(
      3,
      2
    )} ${cleanValue.substr(5, 3)} ${cleanValue.substr(8)}`
  }

  return cleanValue
}
