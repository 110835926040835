import React from 'react'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'
import { DropZoneProps, FileInput } from './FileInput'

export type FileInputControlledProps<
  T extends FieldValues = FieldValues
> = DropZoneProps &
  UseControllerProps<T> & {
    label: string
    btnText?: string
    /**
     * Agrega un icono al costado derecho del basurero
     * para poder agregar alguna función para ver/descargar el archivo
     * seleccionado
     */
    onDownloadFile?: () => void
  }

/**
 * Basado en código de https://github.com/react-hook-form/react-hook-form/discussions/2146#discussioncomment-579355
 */
export function FileInputControlled<T extends FieldValues = FieldValues>(
  props: FileInputControlledProps<T>
) {
  const {
    btnText,
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
    label,
    onDownloadFile,
    ...dropZoneOptions
  } = props
  return (
    <Controller
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      control={control}
      render={({ field, fieldState }) => (
        <FileInput
          btnText={btnText}
          {...dropZoneOptions}
          label={label}
          name={field.name}
          error={fieldState.error?.message}
          onChange={field.onChange}
          value={field.value}
          onDownloadFile={onDownloadFile}
        />
      )}
    />
  )
}
