import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'

export type AuthContainerProps = {
  /**
   * Componente (imagen o svg) del logo del producto Global en color blanco
   */
  brandLogoWhite: React.ReactElement
  /**
   * Componente (imagen o svg) del logo del producto Global en color (Se muestra solo en versión móvil, bajo el card de contenido)
   */
  brandLogoColor?: React.ReactElement
  /**
   * Componente (imagen o svg) de ilustración que se mostrará al lado izquierdo del formulario
   */
  loginImage: React.ReactElement
  /**
   * Contenido que se mostrara en la card blanca
   */
  children?: React.ReactNode
}

export function AuthContainer({
  brandLogoWhite,
  brandLogoColor,
  loginImage,
  children
}: AuthContainerProps) {
  return (
    <Container className='g-auth-container px-xl-5 d-flex'>
      <Row className='py-3 flex-grow-1 justify-content-center align-items-sm-center min-vh-100'>
        <Col lg={6} className='p-0 d-none d-lg-block'>
          <Card
            body
            className='img-auth-card rounded-xxl px-0 pt-3 pb-0 ml-xl-5 mr-lg-n3'
          >
            <Row className='mx-0 pt-4'>
              <Col className='pl-4 pr-1 logo-white' xs={6}>
                {brandLogoWhite}
              </Col>
            </Row>
            <Row className='row flex-grow-1'>
              <Col className='p-0 d-flex align-items-end login-image'>
                {loginImage}
              </Col>
            </Row>
          </Card>
        </Col>

        <Col md={9} lg={6} className='p-lg-0 mx-auto'>
          <Card body className='auth-card px-lg-5 rounded-lg mr-xl-5 ml-lg-n3'>
            <div className='flex-grow-1 py-3 px-1 px-sm-5'>{children}</div>
          </Card>
          {brandLogoColor && (
            <div className='d-block d-lg-none'>
              <div className='pt-4 text-center logo-color'>
                {brandLogoColor}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  )
}
