import React from 'react'
import { Icon } from '../Icon'

export type StepsProps = {
  steps: string[]
  currentStep: number
  /**
   * Colores obtenidos desde las variables (primary and secondary) del tema
   */
  color?: 'primary' | 'secondary'
}

/**
 * Componente utilizado para renderizar distintas variantes de un Steps.
 */
export const Steps = ({
  steps,
  currentStep,
  color = 'primary'
}: StepsProps) => {
  const renderStep = (
    stepNumber: number,
    stepTitle: string,
    status: 'ready' | 'active' | 'pending',
    isLastStep: boolean
  ) => {
    return (
      <div
        key={`step_${stepNumber}`}
        className={`g-step ${status !== 'pending' ? 'active' : ''}`}
      >
        <div className='step-circle-container'>
          <div className='step-circle'>
            <div className='d-flex justify-content-center align-items-center step-number-container '>
              {status === 'ready' ? <Icon name='check' /> : stepNumber}
            </div>
          </div>
        </div>
        <span className='step-title pt-1'>{stepTitle}</span>
        {!isLastStep && <div className={`step-line ${status}`} />}
      </div>
    )
  }

  const getStatusStep = (step: number): 'ready' | 'active' | 'pending' => {
    if (step < currentStep) {
      return 'ready'
    }
    if (step === currentStep) {
      return 'active'
    }
    return 'pending'
  }
  const activeStep = steps.find((_s, i) => getStatusStep(i) === 'active')

  return (
    <div className={`g-step-container g-step-${color}`}>
      <div style={{ overflowX: 'auto' }}>
        <div style={{ display: 'flex', flexWrap: 'nowrap' }}>
          {steps.map((step, i) =>
            renderStep(i + 1, step, getStatusStep(i), i === steps.length - 1)
          )}
        </div>
      </div>
      {Boolean(activeStep) && (
        <h1 className='step-title-mobile'>{activeStep}</h1>
      )}
    </div>
  )
}
