import React, { createContext, useContext } from 'react'
import { CollapseProps } from './Collapse'

const DEFAULT_CONTEXT_VALUE = {
  selectCollapse: () => null,
  isSelected: () => false,
  grouped: false
}

type SelectionKey = string | number | null
type ContextType = {
  selectCollapse: (newSelection: SelectionKey) => void
  isSelected: (id: SelectionKey) => boolean
  grouped: boolean
}

const CollapseGroupContext = createContext<ContextType>(DEFAULT_CONTEXT_VALUE)

export interface CollapseGroupProps {
  accordion?: boolean
  children?:
    | React.ReactElement<CollapseProps>
    | Array<React.ReactElement<CollapseProps>>
}

export function Group({ accordion = true, children }: CollapseGroupProps) {
  if (!children) throw new Error('El grupo debe tener al menos 1 collapse hijo')

  const [selectedCollapsibles, setSelectedCollapsibles] = React.useState<
    SelectionKey[]
  >([])

  const isSelected = React.useCallback(
    (id: SelectionKey) => {
      if (accordion && id === 0) console.log({ selectedCollapsibles, id })
      return selectedCollapsibles.includes(id)
    },
    [selectedCollapsibles, accordion]
  )

  const selectCollapse = React.useCallback(
    (id: SelectionKey) => {
      if (accordion) {
        setSelectedCollapsibles((prev) => (prev.includes(id) ? [] : [id]))
      } else {
        setSelectedCollapsibles((prev) => {
          return prev.includes(id)
            ? prev.filter((c) => c !== id)
            : [...prev, id]
        })
      }
    },
    [isSelected]
  )

  const collapseItems = Array.isArray(children) ? children : [children]

  return (
    <CollapseGroupContext.Provider
      value={{
        selectCollapse,
        isSelected,
        grouped: true
      }}
    >
      {collapseItems?.map((collapseItem, idx) => {
        return {
          ...collapseItem,
          props: {
            ...collapseItem.props,
            id: collapseItem.props.id || idx
          }
        }
      })}
    </CollapseGroupContext.Provider>
  )
}

export function useCollapseGroup() {
  return useContext(CollapseGroupContext)
}
