import React from 'react'
import { Label } from 'reactstrap'
import { Flag } from '../../Flag'
import { components } from 'react-select'

/**
 * Si la opción seleccionada tiene isoCode (utilizado para mostrar banderas)
 * se despliega la bandera al costado izquierdo.
 * También se le agrega el label y el borde de color
 */
const Control = ({ children, ...rest }: any) => {
  const { hasValue, isDisabled, getValue, selectProps } = rest
  const selectedOption = getValue()
  const classHasValue = hasValue ? 'has-value' : 'without-value'
  const classDisabled = isDisabled ? 'disabled' : 'not-disabled'
  const classIcon = selectedOption[0]?.isoCode ? 'with-icon' : 'without-icon'

  return (
    <components.Control
      className={`${classHasValue} ${classDisabled} ${classIcon}`}
      {...rest}
    >
      {selectedOption[0]?.isoCode && (
        <div style={{ marginLeft: '15px', width: '30px', textAlign: 'center' }}>
          <Flag code={selectedOption[0]?.isoCode} size={17} />
        </div>
      )}
      {children}
      <Label for={selectProps.name} className='g-select-label'>
        {selectProps.label.toUpperCase()}
      </Label>
      <div className='g-input-border' />
    </components.Control>
  )
}

export default Control
