import React from 'react'
import { Button as BaseButton } from 'reactstrap'
import clsx from 'clsx'
import { Icon } from '../Icon'
import { IconName } from '../Icon/IconPaths'

export type ButtonProps = {
  /**
   * Texto que tendrá el botón
   * Se permite dejar el texto vacío para crear botones con solo iconos
   */
  text?: string
  /**
   * Colores obtenidos desde las variables (primary and secondary) del tema
   */
  color?: 'primary' | 'secondary' | 'danger'
  type?: 'button' | 'submit' | 'reset'
  /**
   * Tamaño del botón (cambia el alto)
   */
  size?: 'sm' | 'md' | 'lg'
  /**
   * Nombre de uno de los iconos disponibles o un componente react
   * que se agregará al lado izquierdo del texto del botón
   */
  icon?: keyof typeof IconName | React.ReactElement
  className?: string
  outlined?: boolean
  rounded?: boolean
  /**
   * Indica si está deshabilitado (de color gris sin posibilidad de ser presionado)
   */
  disabled?: boolean
  /**
   * Indica si se muestra en estado cargando (con icono girando y color gris)
   */
  loading?: boolean
  /**
   * Indica si el botón se adapta al ancho del padre
   */
  fullwidth?: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  style?: React.CSSProperties
  value?: string
}

/**
 * Componente utilizado para renderizar distintas variantes de un botón.
 */
export const Button = ({
  text,
  color,
  type,
  size,
  icon,
  className,
  style,
  outlined,
  rounded,
  disabled,
  loading,
  fullwidth,
  onClick,
  value
}: ButtonProps) => {
  const renderIcon = () => {
    return (
      <div className={clsx('icon-btn', text !== '' && 'pr-2')}>
        {typeof icon === 'string' ? <Icon name={icon} /> : icon}
      </div>
    )
  }

  return (
    <BaseButton
      block={fullwidth}
      className={`g-button ${rounded && 'btn-rounded'} ${className}`}
      color={disabled || loading ? 'dark' : color}
      outline={outlined}
      size={size}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      style={style}
      value={value}
    >
      {loading ? (
        <Icon name='circle' className='spin icon-loading' />
      ) : (
        <div className='d-flex align-items-center justify-content-center'>
          {icon && renderIcon()}
          {text && text !== '' && <span>{text.toUpperCase()}</span>}
        </div>
      )}
    </BaseButton>
  )
}

Button.defaultProps = {
  color: 'primary',
  type: 'button',
  size: 'lg',
  className: '',
  outlined: false,
  rounded: false,
  disabled: false,
  loading: false,
  fullwidth: false
}
