import React from 'react'
import { useMobile } from '../../hooks'
import { TableCell } from './TableCell'
import { ColumnTable } from './types/TableTypes'

type TableBodyProps = {
  columns: ColumnTable[]
  data: any[]
}

export const TableBody = ({ columns, data }: TableBodyProps) => {
  const isMobile = useMobile()

  return (
    <tbody>
      {data.map((res, i) => {
        return (
          <tr key={`row_${i}`}>
            {columns.map((col, j) => {
              if (isMobile && col.hideOnMobile) return

              const options = {
                row: res,
                value: res[col.columnName],
                index: i,
                data
              }

              const className =
                typeof col.tdClassName === 'function'
                  ? col.tdClassName(options)
                  : col.tdClassName

              return (
                <TableCell
                  className={className}
                  key={`body_${i}_${j}`}
                  bold={col.bold}
                  width={col.width}
                >
                  {col.cellFormat
                    ? col.cellFormat(options)
                    : res[col.columnName]}
                </TableCell>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}
