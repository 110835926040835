import React from 'react'
import { Card, Col, Row } from 'reactstrap'
import { Icon } from '../../Icon'

export type LoadingProps = {
  textBody: string | JSX.Element
  insideCard?: boolean
  className?: string
}

export const Loading = ({
  textBody,
  insideCard = false,
  className
}: LoadingProps) => {
  const Content = () => (
    <div
      className={className}
      style={{ maxWidth: '400px', alignSelf: 'center' }}
    >
      <Row className='text-center py-4 px-3'>
        <Col xs={12}>
          <Icon className='spin' name='circle' color='secondary' size='40px' />
        </Col>
        <Col xs={12} className='py-4'>
          <span className='fs-20 text-medium'>{textBody}</span>
        </Col>
      </Row>
    </div>
  )

  if (insideCard) {
    return (
      <Card className='py-4'>
        <Content />
      </Card>
    )
  } else {
    return <Content />
  }
}
