import React from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Icon } from '../Icon'
import { IconName } from '../Icon/IconPaths'
import 'react-toastify/dist/ReactToastify.css'

export function ToastProvider({ children }: { children: React.ReactChild }) {
  return (
    <React.Fragment>
      {children}
      <ToastContainer position='top-center' />
    </React.Fragment>
  )
}

export type ToastProps = {
  /**
   * Texto que tendrá el toast
   */
  text: string
  /**
   * Colores obtenidos desde las variables (success and danger) del tema
   */
  color?: 'success' | 'danger' | 'warning'
  /**
   * Nombre de uno de los iconos disponibles o un componente react
   * que se agregará al lado izquierdo del texto del toast
   */
  icon?: keyof typeof IconName | React.ReactElement
  /**
   * Retraso en ms para cerrar el toast. Si es false, la notificación debe
   * ser cerrada manualmente
   */
  autoClose?: false | number
  /**
   * Un React Component para reemplazar el botón de cerrar o false para
   * esconder el botón. Por defecto false
   */
  closeButton?: false | React.ReactElement
  className?: string
  style?: React.CSSProperties
}

export function addToast({
  icon,
  color = 'success',
  text,
  className = '',
  style,
  autoClose,
  closeButton = false
}: ToastProps) {
  return toast(
    <div className='d-flex align-items-center'>
      {icon && (
        <div className='icon-btn'>
          {typeof icon === 'string' ? <Icon name={icon} size='24px' /> : icon}
        </div>
      )}
      <span>{text}</span>
    </div>,
    {
      type: color === 'danger' ? 'error' : color,
      style,
      className: `g-toast g-toast-${color} ${className}`,
      autoClose,
      closeButton
    }
  )
}

addToast.defaultProps = {
  color: 'success',
  className: '',
  closeButton: false
}
