import {
  format as formatRut,
  validate as isValidRut,
  clean as cleanRut
} from 'rut.js'

export const rutFormatter = (value: string): string => {
  if (isValidRut(cleanRut(value))) {
    return formatRut(value)
  } else {
    return cleanRut(value)
  }
}
