export enum ValidatorType {
  'cl-mobile',
  'cl-phone-met',
  'cl-phone-regions',
  'default'
}

export const isValidPhone = (
  phone: string,
  type: keyof typeof ValidatorType = 'default'
) => {
  const validators = {
    'cl-mobile': /^\+569[0-9]{8}$/,
    'cl-phone-met': /^\+562[0-9]{8}$/,
    'cl-phone-regions': /^\+56[0-9]{2}[0-9]{7}$/,
    default: /^\+[0-9]{1,2}[0-9]{7,8}$/
  }
  return validators[type].test(phone)
}
