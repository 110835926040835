import React, { CSSProperties, Dispatch, SetStateAction } from 'react'
import clsx from 'clsx'
import { Icon } from '../Icon'
import { useMobile } from '../../hooks/windows/useMobile'

type TabId = number | string
type TabSize = 'sm' | 'md' | 'lg'

interface TabsContextValues {
  currentTab: TabId
  setCurrentTab: Dispatch<SetStateAction<TabId>>
  size?: TabSize
}

const TabsContext = React.createContext<TabsContextValues>({
  currentTab: 0,
  setCurrentTab: () => null
})

/*
 * Componente padre que sostiene el estado de las tabs y emite el cambio de tab
 */
export function Tabs({
  children,
  defaultTab,
  onSelection = () => null,
  size = 'md'
}: {
  children?: React.ReactNode
  defaultTab?: TabId
  onSelection?: (newSelected: TabId) => void
  size?: TabSize
}) {
  const [currentTab, setCurrentTab] = React.useState<TabId>(defaultTab || 0)

  React.useEffect(() => {
    onSelection(currentTab)
  }, [currentTab])

  return (
    <TabsContext.Provider value={{ currentTab, setCurrentTab, size }}>
      <nav className='g-tabs'>{children}</nav>
    </TabsContext.Provider>
  )
}

/**
 * Contenedor de las tabs que les asigna un id basado en el indice (reescribible via props a cada tab) y controla la paginacion
 */
const PAGE_SIZE = 2

export function TabsList({
  children
}: {
  children?: JSX.Element[] | JSX.Element
}) {
  const isMobile = useMobile()
  const [currentPage, setCurrentPage] = React.useState(0)

  const pageStartIdx = currentPage * PAGE_SIZE
  const pageEndIdx = pageStartIdx + PAGE_SIZE

  const tabs = Array.isArray(children) ? children : [children]

  const paginatedChildren = !isMobile
    ? tabs
    : tabs?.slice(pageStartIdx, pageEndIdx)

  const lastValidPage = Math.round((tabs?.length || 0) / PAGE_SIZE) - 1

  const hasPrevPage = currentPage - 1 >= 0
  const hasNextPage = currentPage + 1 <= lastValidPage

  const showPagination = isMobile && tabs && tabs.length > PAGE_SIZE

  return (
    <div>
      <ul className='g-tabs-list'>
        {paginatedChildren?.map((child, idx) => {
          const id = child?.props.id || idx + currentPage * PAGE_SIZE
          return {
            ...child,
            props: { ...child?.props, id }
          }
        })}
        {showPagination && hasPrevPage && (
          <button
            className='g-tabs-pagination-btn left'
            onClick={() => setCurrentPage((prev) => prev - 1)}
          >
            <Icon name='chevron_left' size='60%' />
          </button>
        )}
        {showPagination && hasNextPage && (
          <button
            className='g-tabs-pagination-btn right'
            onClick={() => setCurrentPage((prev) => prev + 1)}
          >
            <Icon name='chevron_right' size='60%' />
          </button>
        )}
      </ul>
    </div>
  )
}

/**
 * Esta es la tab. Al clickearla se selecciona esa tab como la actual
 */
export function Tab({
  id = 0,
  children,
  disabled = false
}: {
  id?: TabId
  children?: React.ReactNode
  disabled?: boolean
}) {
  const { currentTab, setCurrentTab, size } = React.useContext(TabsContext)
  const isCurrentlySelected = currentTab === id

  return (
    <li className={clsx('g-tab', isCurrentlySelected && 'selected', size)}>
      <button onClick={() => setCurrentTab(id)} role='tab' disabled={disabled}>
        {children}
      </button>
    </li>
  )
}

/**
 * Este componente provee de un id numerico y secuencial a los paneles. En caso de no proveer un id directamente a cada panel, el se encargara de asignar el indice
 */
export function TabPanels({
  children,
  panelsClassName,
  panelsStyle
}: {
  children: JSX.Element[] | JSX.Element
  panelsClassName?: string
  panelsStyle?: CSSProperties
}) {
  const panels = Array.isArray(children) ? children : [children]

  return (
    <React.Fragment>
      {panels?.map((child, idx) => {
        const id = child.props.id || idx
        return {
          ...child,
          props: {
            ...child.props,
            id,
            className: `${panelsClassName} ${child.props.className}`,
            style: { ...panelsStyle, ...child.props.style }
          }
        }
      })}
    </React.Fragment>
  )
}

/**
 * Componente cuyo contenido se asocia a una tab. Si la tab seleccionada es la que corresponde al panel se muestra su contenido
 */
export function TabPanel({
  id,
  children,
  className,
  style = {}
}: {
  id?: TabId
  children?: React.ReactNode
  className?: string
  style?: CSSProperties
}) {
  const { currentTab } = React.useContext(TabsContext)
  return (
    <React.Fragment>
      {currentTab === id && (
        <section className={className} style={style}>
          {children}
        </section>
      )}
    </React.Fragment>
  )
}
