import React, { createContext, ReactChild, useContext } from 'react'
import { useSidenavState } from './useSidenavState'

export interface SidenavContextProps
  extends ReturnType<typeof useSidenavState> {
  foundProvider: boolean
}

export const SidenavContext = createContext<SidenavContextProps>({
  state: 'initial',
  toggle: () => null,
  reset: () => null,
  foundProvider: false
})

export interface SidenavProviderProps {
  children: ReactChild
}

export const SidenavProvider = (props: SidenavProviderProps) => {
  const { children } = props
  const { state, toggle, reset } = useSidenavState()

  return (
    <SidenavContext.Provider
      value={{
        state,
        toggle,
        reset,
        foundProvider: true
      }}
      children={children}
    />
  )
}

export function useSidenav() {
  return useContext(SidenavContext)
}
