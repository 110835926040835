import React, { FunctionComponent } from 'react'
import { Modal as ModalBase, ModalBody } from 'reactstrap'

export type ModalProps = {
  /**
   * Indica si el modal está abierto
   */
  isOpen: boolean
  /**
   * Función para cerrar el modal
   */
  toggle: () => void
  /**
   * Tamaños disponibles para el modal
   */
  size?: 'sm' | 'md' | 'lg' | 'xl'
  /**
   * Cierra el modal al presionar en el fondo
   */
  closeOnBackdrop?: boolean
  /**
   * Indica si el modal se encuentra centrado en la pantalla
   */
  centered?: boolean
  /**
   * Llamado en componentDidMount
   */
  onEnter?: () => void
  /**
   * Llamado en componentWillUnmount
   */
  onExit?: () => void
  /**
   * Llamado cuando se hace la transición de entrada
   */
  onOpened?: () => void
  /**
   * Llamado cuando se hace la transición de salida
   */
  onClosed?: () => void
  /**
   * Si el modal debe removerse al cerrar
   */
  unmountOnClose?: boolean
  /**
   * Contenido que se mostrara en la card blanca
   */
  children?: React.ReactNode
}

/**
 * Modal con estilos de global
 */
export const Modal: FunctionComponent<ModalProps> = ({
  isOpen,
  toggle,
  size,
  closeOnBackdrop,
  centered,
  children,
  ...rest
}) => {
  return (
    <ModalBase
      isOpen={isOpen}
      toggle={toggle}
      className='g-modal'
      centered={centered}
      size={size}
      backdrop={closeOnBackdrop === false ? 'static' : true}
      {...rest}
    >
      <ModalBody>{children}</ModalBody>
    </ModalBase>
  )
}

Modal.defaultProps = {
  size: 'md',
  closeOnBackdrop: true,
  centered: true
}
