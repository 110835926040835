import clsx from 'clsx'
import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label } from 'reactstrap'

export interface CheckInputProps<T> extends UseControllerProps<T> {
  label: string
  disabled?: boolean
  ref?: React.LegacyRef<HTMLInputElement>
  value?: any
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  errorText?: string
  readOnly?: boolean
}

export const CheckInput = <T extends FieldValues>(
  props: CheckInputProps<T>
) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const { label, disabled, errorText, readOnly } = props

  const { name, value, onChange } = controlledProps ? controlledProps : props

  return (
    <FormGroup>
      <Label
        for={name}
        className={clsx(
          'g-checkbox-input-container',
          disabled && 'checkbox-disabled',
          readOnly && 'read-only'
        )}
      >
        {label}
        <input
          className={`g-checkbox-input`}
          type='checkbox'
          id={name}
          name={name}
          disabled={disabled}
          readOnly={readOnly}
          onChange={onChange}
          checked={!!value}
        />
        <span className='checkmark'></span>
      </Label>
      <FormText className='g-input-error' color='danger'>
        {errorText || controlledFieldState?.error?.message || ''}
      </FormText>
    </FormGroup>
  )
}

CheckInput.defaultProps = {
  disabled: false,
  value: false
}
